import axios from "axios";


const keyUser = localStorage.getItem("userKey");
const tokenUser = JSON.parse(keyUser);

axios.defaults.headers.common["Authorization"] = `Bearer ${tokenUser}`;


export default {

    LabAddNew: (labsItens) => {
        return axios.post(
            `${process.env.VUE_APP_BASE_URL}/laboratorio/novo`, labsItens)
    },


    LabUpdate: (labsItens) => {
        return axios.post(
            `${process.env.VUE_APP_BASE_URL}/laboratorio/editar`, labsItens)

    },



}


//https://hapi-farmafacil.prismafive.com.br/swagger/index.html
