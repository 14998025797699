//import Methods from "@/components/methods/methods.vue";
//import postMethods from '@/services/methods/postMethods';
import LabsAPI from "@/services/labs/httpLabs";
import axios from "axios";




export default {
    name: "Lab",
    data() {
        return {
            items: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            searchMethods: '',

            pgNumb: '',

            // about pages
            totalPages: '',
            itensQtd: '',

            // body methods
            labsAddItens: {
                contaId: '3c5d1b6c-c0c3-4774-a307-992c25c2bda2',
                integracaoId: "",
                nome: "",
            },
            allItens: [],

            // receive methods listItens
            itensMethods: [],
        };
    },
    components: {
        //  Methods,
    },

    methods: {
        previousPage(page) {
            if (page === this.currentPage && (page <= this.totalPages && page > 1)) {
                this.currentPage -= 1
                this.listItens()
                return
            }

            this.currentPage = page;
            this.listItens()
        },
        nextPage(page) {
            if (page === this.currentPage && (page < this.totalPages && page >= 1)) {
                this.currentPage += 1
                this.listItens()
                return
            }

            this.currentPage = page;
            this.listItens()
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        //Get list Mehtods and pages from Api
        listItens() {
            axios
                .get(`${process.env.VUE_APP_BASE_URL}/laboratorio/obter-grid/${this.currentPage}`)
                .then(res => {
                    this.itensMethods = res.data.itens;
                    this.totalPages = res.data.totalDePaginas;
                    this.currentPage = res.data.pagina;
                    this.itensQtd = res.data.total;


                });
        },

        //Send itens values to api - button Salvar
        saveItens() {
            LabsAPI.LabAddNew(this.labsAddItens)
                .then(res => {
                    alert("salvo com sucesso", res),
                        this.listItens(); // update item on screen 
                    this.labsAddItens = {}; //clear fields
                });
        },

        //To edit values itens - button Editar
        editAction(labsAddItens) {
            this.labsAddItens = labsAddItens
        },

        // Send edited values to APi
        updateItens() {
            LabsAPI.LabUpdate(this.labsAddItens)
                .then(res => {
                    alert("alterado com sucesso", res),
                        this.listItens(); // update item on screen 
                    this.labsAddItens = {}; //clear

                });
        },

        // Delet Itens from Api - button Apagar
        deletItens(labsAddItens) {
            if (confirm('Deseja excluir?')) {
                axios.post(
                    `${process.env.VUE_APP_BASE_URL}/laboratorio/remover/` + labsAddItens.id,
                )
                    .then((res) => {
                        alert("Removido com sucesso", res),
                            this.listItens();
                        this.errors = []
                    })
            }
        },


        // Filter
        allDescrition() {
            let i = 1
            let allPages = '3'
            let arr = []

            //console.log('Total pages', allPages)   

            for (i = 1; i <= allPages; i++) {
                arr.push(axios.get(`${process.env.VUE_APP_BASE_URL}/metodo/obter-grid/` + i))
                Promise.all(arr).then((res) => {
                    arr = res

                    console.log('Todos itens', arr)
                    // console.table(okk);
                })
            }
        },

    },//End Methods

    mounted() {
        //Start with itens list
        this.totalRows = this.items.length
        this.listItens();
        this.$store.dispatch('methods/methodsSearch')

        //this.allDescrition();

        // console.log('Todos itens',  this.allItens)    
    },

    computed: {

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        //Function filter Search
        itensFiltered() {
            let values = []
            values = this.itensMethods.filter((description) => {
                return description.nome.toLowerCase().indexOf(this.searchMethods.toLowerCase()) > -1
            });
            return values;
        },

        itensFilMethods() {
            const { methods } = this.$store.state.methods
            let valuesMe = []
            valuesMe = methods.filter((description) => {
                return description.nome.toLowerCase().indexOf(this.searchMethods.toLowerCase()) > -1
            });
            return valuesMe;
        },

        // renderItens(){
        // const { methods } = this.$store.state.methods
        //  console.log('methods', methods)
        //   return methods;
        // }
    },
};



